
import Vue from "vue";
import {
  endorsementMapActions,
  endorsementMapMutations,
  endorsementMapState
} from "@/store/modules/endorsements";
import * as types from "@/store/mutation-types";
import { endorsementsDefault } from "@/helpers/endorsementsDefault";
import { FormBlock } from "@/components/FormBuilder/types";
import { endorsementsForm } from "@/forms/admin/endorsements";
import FormBuilder from "@/components/FormBuilder/FormBuilder.vue";
import {
  companyLineOfBusiness,
  FormsByPolicyType
} from "../../../helpers/selectOptions";
import { removeEmptyFields } from "../../../helpers/removeEmptyFields";

interface ICreateEndorsement {
  errorMessage: string;
  successMessage: string;
  validationData: Record<string, any>;
  loadingText: string;
}
export default Vue.extend({
  components: { FormBuilder },
  name: "CreateEndorsement",
  data(): ICreateEndorsement {
    return {
      errorMessage: "",
      successMessage: "",
      validationData: {},
      loadingText: "Submitting new endorsement data. Please wait..."
    };
  },
  created() {
    this.SET_EDIT(endorsementsDefault({}));
  },
  methods: {
    ...endorsementMapActions(["createEndorsement"]),
    ...endorsementMapMutations(["SET_EDIT"]),
    ...endorsementMapMutations({ editField: types.SET_EDIT_FIELD }),
    formFieldChangedHandler({ key, value }: { key: any; value: any }): void {
      this.editField({ key, value });
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "create":
          this.submit();
          break;
        case "cancel":
          this.cancelCallback();
          break;
      }
    },
    async cancelCallback(): Promise<any> {
      this.$router.push("/admin/endorsements").catch(() => {});
    },
    async submit(): Promise<void> {
      try {
        if (this.formIsValid) {
          const fieldData = this.editing;
          await this.createEndorsement(
            removeEmptyFields(fieldData as any)
          ).then(() => {
            this.successMessage = "Endorsement created successfully";
            this.$router.replace("/admin/endorsements").catch(() => {});
          });
        } else {
          this.errorMessage = "Please Fill All Required Fields.";
        }
      } catch (err) {
        this.errorMessage = err.message;
        this.$bugSnagClient.notify(err);
      }
    }
  },
  computed: {
    ...endorsementMapState(["editing", "makingApiRequest"]),
    formIsValid(): boolean {
      return !!this.validationData.formIsValid;
    },
    formFields(): FormBlock[] {
      return endorsementsForm;
    },
    companyOptions(): { label: string; value: string }[] {
      const initData = this.$store.state.auth["initData"];
      const { companies = [] } = initData;
      return companies.map((company: any) => ({
        label: company.companyName,
        value: company.companyNumber
      }));
    },
    formTypesOptions(): { label: string; value: string }[] {
      let options = [] as any;
      for (const [key, value] of Object.entries(FormsByPolicyType)) {
        options.push({
          label: value,
          value: key
        });
      }
      return options;
    },
    appliesToOptions(): { label: string; value: string }[] {
      return companyLineOfBusiness.map((option: any) => ({
        label: option.label,
        value: option.value
      }));
    }
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  }
});
