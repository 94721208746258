import { IEndorsement } from "@/store/modules/endorsements/types";
import defaultsDeep from "lodash/defaultsDeep";
import { cloneDeep } from "lodash";
import { baseEndorsement } from "@/helpers/defaultObjects";

export function endorsementsDefault(
  endorsements: Partial<IEndorsement>
): IEndorsement {
  return defaultsDeep(
    cloneDeep(endorsements),
    Object.assign(baseEndorsement())
  );
}
